import { RouteComponentProps } from '@reach/router';
import { classdFn } from 'classd';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { useEffect } from 'react';
import { useUID } from 'react-uid';
import { useMediaLayout } from 'use-media';

import { SampleDocumentPage, Text, TimelineCard } from '../styles/common';
import { ViewportSize } from '../utils/media-queries-utils';
import { Colors } from '../utils/style-utils';
import { Card } from './Card';
import { HeroContainer } from './HeroContainer';
import { Quotes } from './Quotes';
import { Step, Timeline } from './Timeline';
import { H1, H3, H4, Typography } from './Typography';

export function DefaultContent({ location }: { location?: RouteComponentProps['location'] }): JSX.Element {
  const id = useUID();
  const isWide = useMediaLayout({ minWidth: ViewportSize.laptop });
  const staticData = useStaticQuery(graphql`
    query {
      sampleDocFront: file(relativePath: { eq: "sample-doc-front.png" }) {
        childImageSharp {
          fixed(width: 369) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sampleDocBack: file(relativePath: { eq: "sample-doc-back.png" }) {
        childImageSharp {
          fixed(width: 382) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      timelineStep1: file(relativePath: { eq: "timeline-step-1.png" }) {
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      timelineStep2: file(relativePath: { eq: "timeline-step-2.png" }) {
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      timelineStep3: file(relativePath: { eq: "timeline-step-3.png" }) {
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      timelineStep4: file(relativePath: { eq: "timeline-step-4.png" }) {
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (typeof window !== 'undefined' && location?.hash.substring(1) === 'how-it-works') {
      const scrollToDiv = document.getElementById(id);
      scrollToDiv?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location, location?.hash]);

  return (
    <HeroContainer>
      <Card hero className={classdFn('flex flex-col lg:flex-row mb-20 ml-auto mr-auto', { wide: isWide })}>
        <div className="flex-1 text-center lg:text-left pt-4 md:pt-8 lg:pt-16 pl-4 md:pl-10 lg:pl-24 pr-4 md:pr-10 lg:pr-0 order-last lg:order-first">
          <H1 color={Colors.Purple300} className="mb-4 lg:mb-16">
            Streamline Hyperlink Creation by Letting HyperCite® Do the Work
          </H1>
          <div className="pr-4">
            <H4 style={{ fontSize: 22 }} className="mb-2">
              Made for Attorneys and The Court
            </H4>
            <Typography color="#636363" className="mb-4 lg:mb-8" style={{ fontSize: 16, lineHeight: '26px' }}>
              HyperCite® autonomously transforms a brief, motion, or other appellate document’s citations to the record
              on appeal, prior briefing, and more into hyperlinks that navigate the reader to the exact page cited.
            </Typography>
            <H4 style={{ fontSize: 22 }} className="mb-2">
              Save time
            </H4>
            <Typography color="#636363" className="mb-10 lg:mb-16" style={{ fontSize: 16, lineHeight: '26px' }}>
              Hyperlink citations quickly to check your own work, opposing counsel’s work, establish credibility with
              the court, and even to quickly answer questions at oral argument with hyperlinked notes!
            </Typography>
          </div>
        </div>

        <div
          className="flex-0 flex justify-center pt-4 md:pt-8 lg:pt-0 pl-4 md:pl-8 lg:pl-0 pr-4 md:pr-8 lg:pr-0 order-first lg:order-last"
          style={{ minWidth: isWide ? 470 : 'auto' }}
        >
          <SampleDocumentPage className="relative lg:absolute" wide={isWide} style={{ zIndex: 2 }}>
            <Img style={{ marginLeft: -4 }} fixed={staticData.sampleDocFront.childImageSharp.fixed} />
          </SampleDocumentPage>

          {isWide && (
            <SampleDocumentPage
              className="relative lg:absolute"
              style={{
                zIndex: 1,
                transform: 'scale(0.95)',
                marginRight: 65,
                bottom: -50,
                top: 'auto',
              }}
            >
              <Img
                style={{ border: '0 none', marginLeft: -4 }}
                fixed={staticData.sampleDocBack.childImageSharp.fixed}
              />
            </SampleDocumentPage>
          )}
        </div>
      </Card>

      <div className="flex flex-col ml-auto mr-auto max-w-screen-xl" id={id}>
        <H4 className="mb-12 text-center lg:text-left" style={{ fontSize: 20 }}>
          How it Works
        </H4>
        <Timeline className="mb-16">
          <Step>
            <div className="flex flex-col lg:flex-row">
              <div className="flex-0 mr-20">
                <H3>Upload Your Parent Document File</H3>
                <Typography color="#636363" style={{ fontSize: 16, lineHeight: '26px' }}>
                  A brief, writ petition, motion, petition for review, petition for rehearing, oral argument, and more,
                  upload one in PDF as your parent document to start the linking process
                </Typography>
              </div>
              <div className="flex-1">
                <TimelineCard isWide={isWide} className="px-4 lg:px-0">
                  <Img
                    style={{ maxWidth: '100%' }}
                    className="rounded-sm lg:rounded-none"
                    fixed={staticData.timelineStep1.childImageSharp.fixed}
                  />
                </TimelineCard>
              </div>
            </div>
          </Step>

          <Step>
            <div className="flex flex-col lg:flex-row">
              <div className="flex-0 mr-20">
                <H3>Upload Your Child Document File(s)</H3>
                <Typography color="#636363" style={{ fontSize: 16, lineHeight: '26px' }}>
                  Here we go, Reporter’s Transcripts, Clerk’s Transcripts, Appellant’s Appendix, Respondent’s Appendix,
                  supplementals, and more. Go further with prior briefing, like when citing to the AOB or RB in a Reply,
                  prior motions, and even the COA’s Opinion in a Petition for Review!
                </Typography>
              </div>
              <div className="flex-1">
                <TimelineCard isWide={isWide} className="px-4 lg:px-0">
                  <Img
                    style={{ maxWidth: '100%' }}
                    className="rounded-sm lg:rounded-none"
                    fixed={staticData.timelineStep2.childImageSharp.fixed}
                  />
                </TimelineCard>
              </div>
            </div>
          </Step>

          <Step>
            <div className="flex flex-col lg:flex-row">
              <div className="flex-0 mr-20">
                <H3>Tell Us Your Citation Convention(s)</H3>
                <Typography color="#636363" style={{ fontSize: 16, lineHeight: '26px' }}>
                  Associate a citation convention with each child document. In this example (1 C.T. pp. 54-65; 2 C.T.
                  pp. 300, 320; RT 10-11; AOB 16; MJN 13:7-14:5, XYZ pp. 100, 102), enter C.T., R.T., AOB, MJN, or other
                  convention (XZY) underneath the file that corresponds to each convention.
                </Typography>
              </div>
              <div className="flex-1">
                <TimelineCard isWide={isWide} className="px-4 lg:px-0">
                  <Img
                    style={{ maxWidth: '100%' }}
                    className="rounded-sm lg:rounded-none"
                    fixed={staticData.timelineStep3.childImageSharp.fixed}
                  />
                </TimelineCard>
              </div>
            </div>
          </Step>

          <Step>
            <div className="flex flex-col lg:flex-row">
              <div className="flex-0 mr-20">
                <H3>Submit and Download</H3>
                <Typography color="#636363" style={{ fontSize: 16, lineHeight: '26px' }}>
                  Hit submit and leave the rest to us. Whether you enclose your citation in brackets, parenthesis, or
                  nothing, HyperCite® will find. Volume or line numbers, no problem. In little time, you will receive a
                  single PDF containing your parent document with citations hyperlinked to your newly attached child
                  documents.
                </Typography>
              </div>
              <div className="flex-1">
                <TimelineCard isWide={isWide} className="px-4 lg:px-0">
                  <Img
                    style={{ maxWidth: '100%' }}
                    className="rounded-sm lg:rounded-none"
                    fixed={staticData.timelineStep4.childImageSharp.fixed}
                  />
                </TimelineCard>
              </div>
            </div>
          </Step>
        </Timeline>
      </div>

      <div className="flex flex-col ml-auto mr-auto mt-20 mb-16 max-w-screen-xl lg:px-8">
        <div className="w-full md:w-2/3 flex flex-col items-start">
          <Text className="mb-12">Important</Text>
          <H3 color="#051392" className="mb-12">
            You must open the file in a PDF program (like Acrobat), not your web-browser’s viewer. To do so, open from
            the saved file on your computer, not the browser.
          </H3>
        </div>
      </div>

      <div className="flex flex-col ml-auto mr-auto max-w-screen-xl">
        <Quotes />
      </div>
    </HeroContainer>
  );
}
