import { omit } from 'ramda';
import React, { FC, HTMLAttributes, isValidElement } from 'react';
import styled from 'styled-components';

import { Device } from '../utils/media-queries-utils';
import { H3 } from './Typography';

export interface TimelineStep {
  title?: string;
}

const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-left: 1px solid rgba(0, 25, 255, 0.25);
`;

const TimelineStep = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 190px;
  padding-left: 58px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: ' ';
    position: absolute;
    left: -7px;
    top: 14px;
    background: white;
    border: 1px solid #0019ff;
    border-radius: 100%;
    width: 13px;
    height: 13px;
    box-sizing: border-box;
  }

  ${H3} {
    margin-top: 0;
    margin-bottom: 25px;
    color: #051392;
  }

  @media ${Device.laptop} {
    margin-bottom: 30px;
  }
`;

export const Timeline: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <TimelineContainer {...omit(['children'], props)}>
      {React.Children.toArray(props.children).filter(isValidElement)}
    </TimelineContainer>
  );
};

export const Step: FC<TimelineStep> = ({ title, children }) => {
  return (
    <TimelineStep>
      {title && <H3>{title}</H3>}
      {children}
    </TimelineStep>
  );
};
